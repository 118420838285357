<script setup lang="ts">
import { ref } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import { SfSelect, SfInput, SfCheckbox, SfButton, SfRadio, SfListItem } from '@storefront-ui/vue';

import ApplePayLogo from '@/assets/images/apple-pay-logo.svg';
import GooglePayLogo from '@/assets/images/google-pay-logo.svg';
import PayPalLogo from '@/assets/images/paypal-logo.svg';
import VisaLogo from '@/assets/images/visa-logo.svg';

// Here you should provide a list of countries you want to support
// or use an up-to-date country list like: https://www.npmjs.com/package/country-list
const countries = ['Germany', 'Great Britain', 'Poland', 'United States of America'] as const;
const states = ['California', 'Florida', 'New York', 'Texas'] as const;

const streetIsValid = ref(true);

const deliveryOptions = [
    {
        name: 'Standard',
        cost: 'Free',
        date: 'Thursday, September 15',
    },
    {
        name: 'Express',
        cost: '$5.00',
        date: 'Tomorrow, September 12',
    },
];
const radioModel = ref('');

const onSubmit = (e: Event) => {
    /* your submit handler, e.g.: */
    const form = e.target as HTMLFormElement;

    // data can be accessed in form of FormData
    const formData = new FormData(form);
    // or JSON object
    const formJSON = Object.fromEntries(formData.entries());
    console.log(formJSON);
};

// List of payment methods
const paymentMethods = [
    {
        label: 'Credit card',
        value: 'credit-card',
        logo: VisaLogo,
        active: true,
    },
    {
        label: 'PayPal',
        value: 'paypal',
        logo: PayPalLogo,
        active: true,
    },
    {
        label: 'ApplePay',
        value: 'applepay',
        logo: ApplePayLogo,
        active: true,
    },
    {
        label: 'GooglePay',
        value: 'googlepay',
        logo: GooglePayLogo,
        active: false,
    },
];

defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
});
</script>

<template>
    <Head title="Vendu Payment" />

    <fieldset>
        <legend class="mb-4 typography-headline-5 font-bold text-neutral-900">Payment method</legend>
        <div class="grid grid-cols-2 gap-4 items-stretch">
            <label v-for="{ label, value, logo, active } in paymentMethods" :key="value" class="relative">
                <input type="radio" name="payment_method" class="peer sr-only" :value="value" :disabled="!active" />
                <div
                    class="h-full flex flex-col items-center justify-center py-7 px-4 cursor-pointer rounded-md border border-neutral-200 -outline-offset-2 hover:border-primary-200 hover:bg-primary-100 peer-focus:border-primary-200 peer-focus:bg-primary-100 active:border-primary-300 active:bg-primary-200 peer-checked:outline peer-checked:outline-2 peer-checked:outline-primary-700 peer-disabled:opacity-50 peer-disabled:bg-neutral-100 peer-disabled:border-neutral-200 peer-disabled:cursor-not-allowed peer-disabled:[&_img]:grayscale"
                >
                    <img :src="logo" :alt="label" class="h-6 select-none" />
                    <p v-if="!active" class="absolute bottom-2 select-none text-disabled-900 typography-text-xs">Coming soon</p>
                </div>
            </label>
        </div>
    </fieldset>

    <SfListItem
        v-for="({ name, cost, date }, index) in deliveryOptions"
        :key="index"
        tag="label"
        :selected="radioModel == name"
        class="!items-start max-w-sm border rounded-md border-neutral-200 first-of-type:mr-4 first-of-type:mb-4"
        :class="{ '-outline-offset-2 outline-primary-700 outline outline-2 ': radioModel == name }"
    >
        {{ name }}
        <span class="text-xs text-gray-500 break-words">{{ date }}</span>
        <template #prefix>
            <SfRadio v-model="radioModel" :value="name" name="delivery-options" class="peers" />
        </template>
        <template #suffix>
            <span class="text-gray-900">{{ cost }}</span>
        </template>
    </SfListItem>

    <form class="p-4 flex gap-4 flex-wrap text-neutral-900" @submit.prevent="onSubmit">
        <h2 class="w-full typography-headline-4 md:typography-headline-3 font-bold">Billing address</h2>
        <label class="w-full md:w-auto flex-grow flex flex-col gap-0.5 mt-4 md:mt-0">
            <span class="typography-text-sm font-medium">First Name</span>
            <SfInput name="firstName" autocomplete="given-name" required />
        </label>
        <label class="w-full md:w-auto flex-grow flex flex-col gap-0.5">
            <span class="typography-text-sm font-medium">Last Name</span>
            <SfInput name="lastName" autocomplete="family-name" required />
        </label>
        <label class="w-full flex flex-col gap-0.5">
            <span class="typography-text-sm font-medium">Phone</span>
            <SfInput name="phone" type="tel" autocomplete="tel" required />
        </label>
        <label class="w-full flex flex-col gap-0.5 flex flex-col gap-0.5">
            <span class="typography-text-sm font-medium">Country</span>
            <SfSelect name="country" placeholder="-- Select --" autocomplete="country-name" required>
                <option v-for="countryName in countries" :key="countryName">{{ countryName }}</option>
            </SfSelect>
        </label>
        <div class="w-full md:w-auto flex-grow flex flex-col gap-0.5">
            <label>
                <span class="typography-text-sm font-medium">Street</span>
                <SfInput
                    name="street"
                    autocomplete="address-line1"
                    class="mt-.05"
                    required
                    :invalid="!streetIsValid"
                    @blur="streetIsValid = !!$event.target.value"
                    @update:model-value="streetIsValid = !!$event"
                />
            </label>
            <strong v-if="!streetIsValid" class="typography-error-sm text-negative-700 font-medium">
                Please provide a valid street name
            </strong>
            <small class="typography-text-xs text-neutral-500">Street address or P.O. Box</small>
        </div>
        <div class="w-full flex flex-col gap-0.5 md:w-[120px]">
            <label>
                <span class="typography-text-sm font-medium">Apt#, Suite, etc</span>
                <SfInput name="aptNo" class="mt-0.5" />
            </label>
            <small class="typography-text-xs text-neutral-500">Optional</small>
        </div>
        <label class="w-full flex flex-col gap-0.5">
            <span class="typography-text-sm font-medium">City</span>
            <SfInput name="city" autocomplete="address-level2" required />
        </label>
        <label class="w-full md:w-auto flex flex-col gap-0.5 flex-grow">
            <span class="typography-text-sm font-medium">State</span>
            <SfSelect name="state" placeholder="-- Select --" autocomplete="address-level1" required>
                <option v-for="stateName in states" :key="stateName">{{ stateName }}</option>
            </SfSelect>
        </label>
        <label class="w-full flex flex-col gap-0.5 md:w-[120px]">
            <span class="typography-text-sm font-medium">ZIP Code</span>
            <SfInput name="zipCode" placeholder="eg. 12345" autocomplete="postal-code" required />
        </label>

        <label class="w-full flex items-center gap-2">
            <SfCheckbox name="useAsShippingAddress" />
            Use as shipping address
        </label>

        <div class="w-full flex gap-4 mt-4 md:mt-0 md:justify-end">
            <SfButton type="reset" variant="secondary" class="w-full md:w-auto">Clear all</SfButton>
            <SfButton class="w-full md:w-auto">Save</SfButton>
        </div>
    </form>


    <div class="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-100 sm:items-center sm:pt-0">
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div class="flex justify-center pt-8 sm:justify-start sm:pt-0">
                <img :src="asset('images/logo-VPAY.svg')" alt="Vendu Payment">

                <SfButton type="button" class="w-full"> Hello </SfButton>

            </div>
        </div>
    </div>
</template>
